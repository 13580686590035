.app-logo {
  display: inline-block;
  height: 48px;
  margin: 8px;
}

.site-page-header,
.site-layout-background {
  background: #fff;
}

.site-layout-sider {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}

.site-layout-header {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.site-layout {
  text-align: left;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}
