@import url(https://cdn.jsdelivr.net/npm/font-applesdgothicneo@1.0.3/css/all.min.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
html *,
body * {
  font-family: "Spoqa Han Sans Neo", AppleSDGothicNeo, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.formik-caption {
  color: rgba(0, 0, 0, 0.5) !important;
  margin-bottom: 0.5rem;
  font-size: 12px;
}

div.formik-caption.dotted {
  position: relative;
  padding-left: 8px;
}
div.formik-caption.dotted::before {
  content: "";
  width: 3px;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 7px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-logo {
  display: inline-block;
  height: 48px;
  margin: 8px;
}

.site-page-header,
.site-layout-background {
  background: #fff;
}

.site-layout-sider {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}

.site-layout-header {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.site-layout {
  text-align: left;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}

.upload-list-inline .ant-upload-list-item-thumbnail img {
  width: auto;
}

.row-odd {
  background-color: #f3f3f3;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.menu-table-row {
  cursor: pointer;
}
.menu-table-name-column {
  cursor: auto;
}
.menu-table-cell {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}


.signin-row {
  height: 100vh;
  background: #f0f2f5;
}

.signin-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

