.menu-table-row {
  cursor: pointer;
}
.menu-table-name-column {
  cursor: auto;
}
.menu-table-cell {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
