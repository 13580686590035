@import url("https://cdn.jsdelivr.net/npm/font-applesdgothicneo@1.0.3/css/all.min.css");
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
html *,
body * {
  font-family: "Spoqa Han Sans Neo", AppleSDGothicNeo, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.formik-caption {
  color: rgba(0, 0, 0, 0.5) !important;
  margin-bottom: 0.5rem;
  font-size: 12px;
}

div.formik-caption.dotted {
  position: relative;
  padding-left: 8px;
}
div.formik-caption.dotted::before {
  content: "";
  width: 3px;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 7px;
}
